import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import * as actions from "../../redux/actions"
import { connect } from "react-redux"
import Img from "gatsby-image"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import ExternalLink from "../../components/ExternalLink"
import Button from "../../components/ButtonLink"

const Inbetween = ({ authenticated, data }) => {
  useEffect(() => {
    if (authenticated) {
      // wait 5 seconds
      setTimeout(() => {
        navigate("/dashboard")
      }, 5000)
    }
  }, [])

  const horizontalAd = data.horizontalAd.edges[0].node.childImageSharp.fluid
  return (
    <Layout>
      <SEO title="Dixie Bracket | Login" />
      <div className="row py-4">
        <div className="col-12 text-center">
          <h2>Brandon Hunt - State Farm</h2>
        </div>
      </div>
      <div className="row p4-4">
        <div className="col-12 col-md-8 col-lg-6 mr-auto ml-auto">
          <ExternalLink
            href={`https://www.statefarm.com/agent/us/ut/st-george/brandon-hunt-h0j1n7brqak`}
          >
            <Img
              fluid={horizontalAd}
              alt="Brandon Hunt - State Farm"
              imgStyle={{ objectFit: "contain" }}
            />
          </ExternalLink>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12 text-center">
          <Button to="/dashboard">Dashboard</Button>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query InbetweenQuery {
    horizontalAd: allFile(filter: { name: { eq: "horizontal_ad_728x90" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 728) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

const MapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
  }
}

export default connect(MapStateToProps, actions)(Inbetween)
